// import { dispatcher } from '@helpers/redux';
import { showToast } from '@actions/Global';
import i18n from '../../providers/i18n';

// 502,600 de logine atılabilir, http hatalarıda kontrol edilecek
// Change it for yourself
// Multiple controls can be added
const ExceptionMiddlewareAsync = async (res, axiosInstance) => {

  if (res?.data) {
 
    const statusCode = res?.data?.statusCode;

    if ([400, 401, 403, 600].indexOf(statusCode) >= 0 || !statusCode) {

      const message = res?.data?.message;
    
      console.log(`${res?.data?.message} =>>>>> ${res.request.responseURL} =>>> Status Code: ${statusCode}`);
      
      showToast(message, 'warning',);
  
      if (statusCode === 401) {

        axiosInstance.defaults.headers.common.Authorization = null;
        // await removeStoreData('token');
        // dispatcher({ type: $AT.GLOBAL_STATE_CHANGE, payload: { key: 'user', value: {} } });
      
      }
    
    }
  
  } else {

    showToast(i18n.t('globalError'), 'error');
  
  }

};
export default ExceptionMiddlewareAsync;
