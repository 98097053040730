import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { backdropClose } from '@actions/Global';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const BackDropProvider = ({ children }) => {

  const { backdrop } = useSelector((state) => state.GlobalReducer);
  const handleClose = () => backdropClose();

  const classes = useStyles();
  return (
    <>
      {children}
      <Backdrop
        className={classes.backdrop}
        open={backdrop}
        onClick={handleClose}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </>

  );

};
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999999
  },
}));
export default BackDropProvider;
