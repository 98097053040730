import React from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { resetToast } from '@actions/Global';

export default function SnackBarProvider({ children }) {

  const { enqueueSnackbar } = useSnackbar();
  const {
    toast: {
      variant = '',
      message = 'Beklenmeyen Bir Hata Oluştu',
      autoHideDuration
    } 
  } = useSelector((state) => state.GlobalReducer);
  
    
  React.useEffect(() => {

    // Variant => {'default','success','error','warning','info',}
    if (variant) {

      enqueueSnackbar(message, { variant, autoHideDuration: autoHideDuration || 3000 });
  
      setTimeout(() => {

        resetToast(); 

      }, 100);
    
    }
  
  }, [variant]);

  return (
    <>
      {children}
    </>
  );

}
