import { createMuiTheme } from '@material-ui/core/styles';
import { trTR } from '@material-ui/core/locale';
import { primaryColor, secondaryColor } from './Color';

// Create a theme instance.
const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        overflow: 'auto',
      },
    },
    MuiTableCell: {
      head: {
        top: ' -1px !important',
        left: ' 0 !important',
        zIndex: ' 2 !important',
        borderStyle: 'solid !important',
        borderWidth: ' 1px 0px !important',
        borderColor: `${primaryColor} !important`,
        fontWeight: ' bold !important',
        color: ' #FFF !important',
        backgroundColor: `${primaryColor} !important`,
        overflow: ' auto',
        padding: '16px',
      },
      root: {
        padding: '10px',
      }
    },

  },

  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    
    primaryColorBoxShadowFunc: (opacity = '0.3') => `rgba(82, 67, 100, ${opacity})`,
    secondaryColorBoxShadowFunc: (opacity = '0.3') => `rgba(236, 153, 41, ${opacity})`,
  },
}, trTR);

export default theme;
