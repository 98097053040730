import axios from 'axios';
import ExceptionMiddlewareAsync from './ExceptionMiddleware';

// Using the same example everywhere 
const AxiosInstance = axios.create({
  baseURL: 'https://takas.one'
});
  
// To detect errors in api
AxiosInstance.interceptors.response.use((response) => {

  ExceptionMiddlewareAsync(response, AxiosInstance);
  return response;

}, (error) => {

  // TODO Başka bir middlewera yazılacak
  ExceptionMiddlewareAsync(error, AxiosInstance);
  return Promise.reject(error);

});
  
export default AxiosInstance;
