import React from 'react'; 
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@src/assets/theme';
import { SnackbarProvider } from 'notistack';
import Store from '../../Store';
import BackDropProvider from './BackDrop';
import SnackBarProvider from './SnackBar';
import './i18n';

export default function CustomProvider({ children }) {

  return (
    <Provider store={Store}> 
      <ThemeProvider theme={theme}>
        <BackDropProvider>
          <SnackbarProvider maxSnack={3}>
            <SnackBarProvider>        
              {children}
            </SnackBarProvider>
          </SnackbarProvider>
        </BackDropProvider>
      </ThemeProvider> 
    </Provider>
  );

}
