import { dispatcher } from '@src/helpers/redux';
import AxiosInstance from '@src/api/network/AxiosInstance';
import * as $AT from './ActionTypes';

/**
* Global reducer daki değişken güncelleme
* @param  {object} payload
* @ {
* @  `property`: `string`,
* @  `value`: `value`,
* @ } */
export const globalChange = (payload) => {

  dispatcher({ type: $AT.GLOBAL_CHANGE, payload });

};

export const backdropOpen = () => {

  globalChange({ property: 'backdrop', value: true });

};
export const backdropClose = () => {

  globalChange({ property: 'backdrop', value: false });

};

/**
* Snackbar mesaj gösterme
* @param  {string} message
* @param  {string} Variant 'default','success','error','warning','info'
* @param  {number} autoHideDuration
*/
export const showToast = (message, variant = 'success', autoHideDuration) => {

  globalChange({ property: 'toast', value: { variant, message, autoHideDuration } });

};

export const resetToast = () => globalChange({ property: 'toast', value: { variant: '', message: '' } });
export const cookieConfigure = (token, user) => {

  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  
  globalChange({ property: 'user', value: user });

};
