import * as $AT from '@actions/ActionTypes';

const INITIAL_STATE = {
  user: {},
  backdrop: false, 
  toast: {}
};

const GlobalReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {
    case $AT.GLOBAL_CHANGE: {

      return { ...state, [payload.property]: payload.value };
    
    }
    default:
      return state;
  }

};
export default GlobalReducer;
