/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Head from 'next/head';
import '../styles/globals.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomProvider from '@src/providers';

const App = (props) => {

  const { Component, pageProps } = props;
  
  React.useEffect(() => {

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {

      jssStyles.parentElement.removeChild(jssStyles);
    
    }
  
  }, []);

  return (
    <>
      <Head>
        <title>Tweb</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;900&display=swap" rel="stylesheet" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Head>
      <CustomProvider>       
        <CssBaseline />
        <Component {...pageProps} />       
      </CustomProvider>
    </>
  );

};
export default App;

App.getInitialProps = async ({ Component, ctx }) => {

  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
  return { pageProps };

};
